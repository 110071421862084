import React, { useState, useEffect } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/system';
import EditProductModal from './EditProduct';
import axios from 'axios';
import useMainContext from '../../context/useMainContext';
const WhiteCheckbox = styled(Checkbox)({
    color: 'white',
    '&.Mui-checked': {
        color: 'white',
    },
});

export function ProductCard({ product, imageUrl }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const {
        rol,
        arrayProductShop,
        setArrayProductShop, } = useMainContext()
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [imageLoad, setImageLoad] = useState(null)
    const [open, setOpen] = useState(false);
    const [productEdit, setProductEdit] = useState(false)
    const handleOpen = () => {
        setProductEdit(product)
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };
    const handleButtonClick = () => {
        setChecked(!checked);

    };

    const handleAddProductCard = () => {
        const newArrayProduct = [...arrayProductShop]
        newArrayProduct.push(product)
        setArrayProductShop(newArrayProduct)
        console.log('array Product', newArrayProduct)
    }
    const removeProductFromCart = (productId) => {
        const newArrayProduct = arrayProductShop.filter(item => item.id_article !== productId)
        console.log('newArray', newArrayProduct)
        setArrayProductShop(newArrayProduct)
    };
    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();

        const formData = new FormData();
        formData.append('image', image);
        formData.append('id_article', product.id_article);

        try {
            const response = await axios.post(`${apiUrl}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                alert('Imagen subida con éxito');
                console.log(response.data);
            }
        } catch (error) {
            console.error('Error al subir la imagen:', error);
            alert('Hubo un error al subir la imagen');
        }
        finally {
            setLoading(false)
        }
    };

    const handleSave = (updatedProduct) => {

        handleClose();
    };

    let currency = 'U$';
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

    useEffect(() => {
        if (checked) {
            handleAddProductCard()
        } else {
            removeProductFromCart(product.id_article)
        }
    }, [checked])

    useEffect(() => {
        if (arrayProductShop.some(item => item.id_article === product.id_article)) {
            setChecked(true);
        } else {
            setChecked(false)
        }
    }, [arrayProductShop, product.id_article]);

    return (
        <>

            <Card sx={{ 
                // width: {xs:220, sm:250, md:300, lg:270}, 
                // height: {xs:220, sm:250, md:300, lg:270}, 
                // margin: {  xs:7,sm:5, md:0.5, lg:0},
                // boxShadow: 'lg',
                // boxSizing: 'border-box'
                width: { xs: 220, sm: 250, md: 300, lg: 270 }, 
                minHeight: { xs: 220, sm: 250, md: 300, lg: 270 }, 
                margin: { xs: 7, sm: 5, md: 0.5, lg: 0 },
                boxShadow: 'lg',
                boxSizing: 'border-box',
                // display: 'flex',
                // flexDirection: 'column',
                // justifyContent: 'space-between'
             }}
            >
                <CardOverflow>
                    <AspectRatio sx={{ minWidth: 200 }}>
                        <img
                            src={imageUrl}
                            loading="lazy"
                            alt={product?.description || 'Producto'}
                        />
                    </AspectRatio>
                </CardOverflow>
                <CardContent>
                    <Typography level="body-xs" sx={{ mt: 1, fontWeight: 'xl' }}>{product.name}</Typography>
                    <Typography level="title-lg" sx={{ mt: 1, fontWeight: 'xl' }}>
                        {currency} {product.price}
                    </Typography>
                    <Typography level="body-sm">
                        {product.description}
                    </Typography>
                </CardContent>
                {rol == 'Administrador' && (
                    <EditIcon
                        onClick={handleOpen}
                        sx={{ cursor: 'pointer' }} />
                )}

                <CardOverflow>
                    <Button
                        variant="solid"
                        size="lg"
                        color="success"
                        onClick={() => {
                            setChecked(!checked); 
                        }}
                        style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
                    >
                        Agregar al Carrito
                        <WhiteCheckbox
                            {...label}
                            sx={{ left: "10%" }}
                            checked={checked}
                            onClick={(e) => e.stopPropagation()} 
                            onChange={(e) => setChecked(e.target.checked)}
                        />
                    </Button>

                </CardOverflow>
            </Card>
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1300,
                    }}
                >
                    <CircularProgress color="success" />
                </Box>
            )}
            <EditProductModal
                open={open}
                handleClose={handleClose}
                product={productEdit}
                handleSave={handleSave}
            />
        </>

    );
}
