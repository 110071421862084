// import React, { useState, useEffect } from 'react';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Grid } from '@mui/material';
import { ProductCard } from '../utils/ProductCard';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SpecialCategoryCarousel ({ articles }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const itemsPerPage = 5; 
  const maxSteps = Math.ceil(articles.length / itemsPerPage);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (

    <Box sx={{ width: '100%', flexGrow: 1, margin: '1rem 0'}}>
  <AutoPlaySwipeableViews
    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    index={activeStep}
    onChangeIndex={handleStepChange}
    enableMouseEvents
    interval={8000}
  >
    {Array.from({ length: maxSteps }).map((_, pageIndex) => (
      <Box key={pageIndex} sx={{ display: 'flex', justifyContent: 'center', padding: '1rem 0' }}>
        <Grid container spacing={2} justifyContent="center" sx={{ width: '100%', boxSizing: 'border-box' }}>
          {articles.slice(pageIndex * itemsPerPage, pageIndex * itemsPerPage + itemsPerPage).map((product, index) => {
            const imageUrl = product?.image?.url || 'default-image-url.jpg';

            return (
              <Grid item key={index} xs={12} sm={6} md={4} lg={2} sx={{ boxSizing: 'border-box' }}>
                <ProductCard
                  product={product}
                  imageUrl={imageUrl}
                  sx={{ height: '100%', maxHeight: '250px', width: '100%', boxSizing: 'border-box' }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    ))}
  </AutoPlaySwipeableViews>
  <MobileStepper
    steps={maxSteps}
    position="static"
    activeStep={activeStep}
    nextButton={
      <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
        Next
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </Button>
    }
    backButton={
      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        Back
      </Button>
    }
  />
</Box>

  );
};

export default SpecialCategoryCarousel;