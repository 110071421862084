import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useMainContext from '../../context/useMainContext';
import CircularProgress from '@mui/joy/CircularProgress';
import { fetchArticlesBySubCategory } from '../../controllers/articles';

export function MenuLat() {
  const { subCategories, setArticles, menuSubCategories,setShowMainCarrousel } = useMainContext();
  const [openItems, setOpenItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);

  const handleClick = async (id_subCategory) => {
    try {
      setLoading(true);
      const response = await fetchArticlesBySubCategory(id_subCategory);
      console.log('response articles: ', response);
      setArticles(response);
      setLoading(false);
    } catch (error) {
      console.log('Hubo un error: ', error);
      setLoading(false);
    }
  };

  const handleSubCategoryClick = async (id_subCategory) => {
        setLoading(true);
        try {
          const articles = await fetchArticlesBySubCategory(id_subCategory);
          setArticles(articles);
          setShowMainCarrousel(false)
        } catch (error) {
          console.log('Error fetching articles:', error);
        } finally {
          setLoading(false);
        }
      };

  return (
    <>

<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <List component="nav" aria-label="main categories">
        {subCategories?.map(category => (
          <React.Fragment key={category.id_subCategory}>
            <ListItemButton onClick={() => handleClick(category.id_subCategory)}>
                
              <ArrowRightIcon>
                {openItems[category.id_subCategory] ? <ExpandLess /> : <ExpandMore />}
              </ArrowRightIcon>
              <ListItemText primary={category.name} />
            </ListItemButton>
            {activeCategory === category.id_category && (
                <List component="div" disablePadding>
                  {menuSubCategories?.map(subCategory => (
                    <ListItemButton 
                      key={subCategory.id_subCategory} 
                      sx={{ pl: 4 }}
                      onClick={() => handleSubCategoryClick(subCategory.id_subCategory)}
                    >
                      <ArrowRightIcon />
                      <ListItemText primary={subCategory.name} />
                    </ListItemButton>
                  ))}
                </List>
              )}
          </React.Fragment>
        ))}
      </List>
      <Divider />
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.5)' 
          }}
        >
          <CircularProgress color="success" />
        </Box>
      )}
    </Box>


    

    </>
    
  );
}