import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon, Typography, Box, Collapse, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import alimentosPng from '../../Assets/alimentos.png';
import bebidasPng from '../../Assets/refrescos.png';
import higienePng from '../../Assets/higiene-personal.png';
import limpiezaPng from '../../Assets/limpieza.png';
import otrosPng from '../../Assets/otros.png';
import useMainContext from '../../context/useMainContext';
import { fetchArticlesBySubCategory } from '../../controllers/articles';
import { fetchSubCategories } from '../../controllers/subCategories';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function MenuLatMobile() {
  const { subCategories, setSubCategories, setArticles, setShowMainCarrousel } = useMainContext();
  const [open, setOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(null);
  const [loading, setLoading] = useState(false)
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleSubCategoryClick = async (id_subCategory) => {
    setLoading(true);
    try {
      const articles = await fetchArticlesBySubCategory(id_subCategory);
      setArticles(articles);
      setShowMainCarrousel(false)
      toggleDrawer()
    } catch (error) {
      console.log('Error fetching articles:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleClick = async (id_category) => {
    try {
      const response = await fetchSubCategories(id_category);
      console.log('response articles: ', response);
      setSubCategories(response);
      setOpenCategory(openCategory === id_category ? null : id_category);
    } catch (error) {
      console.log('Hubo un error: ', error);
    }
  };

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: '75%',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h4'>
              Menú
            </Typography>
            <ArrowBackIcon onClick={toggleDrawer} />
          </Grid>

          <List>
            {/* Alimentos */}
            <ListItem button onClick={() => handleClick(2)}>
              <ListItemIcon>
                <img src={alimentosPng} alt="Alimentos" style={{ width: '2rem', height: '2rem' }} />
              </ListItemIcon>
              <ListItemText primary="Alimentos" />
              {openCategory === 1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCategory === 2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subCategories.map(subCategory => (
                  <ListItem key={subCategory.id_subCategory} button sx={{ pl: 4 }}>
                    <ListItemText 
                    onClick={()=>{handleSubCategoryClick(subCategory.id_subCategory)}}
                    primary={subCategory.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            {/* Bebidas */}
            <ListItem button onClick={() => handleClick(1)}>
              <ListItemIcon>
                <img src={bebidasPng} alt="Bebidas" style={{ width: '2rem', height: '2rem' }} />
              </ListItemIcon>
              <ListItemText primary="Bebidas" />
              {openCategory === 2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCategory === 1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subCategories.map(subCategory => (
                  <ListItem key={subCategory.id_subCategory} button sx={{ pl: 4 }}>
                    <ListItemText 
                    onClick={()=>{handleSubCategoryClick(subCategory.id_subCategory)}}
                    primary={subCategory.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            {/* Limpieza */}
            <ListItem button onClick={() => handleClick(3)}>
              <ListItemIcon>
                <img src={limpiezaPng} alt="Limpieza" style={{ width: '2rem', height: '2rem' }} />
              </ListItemIcon>
              <ListItemText primary="Limpieza" />
              {openCategory === 3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCategory === 3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subCategories.map(subCategory => (
                  <ListItem key={subCategory.id_subCategory} button sx={{ pl: 4 }}>
                    <ListItemText 
                    onClick={()=>{handleSubCategoryClick(subCategory.id_subCategory)}}
                    primary={subCategory.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            {/* Higiene Personal */}
            <ListItem button onClick={() => handleClick(4)}>
              <ListItemIcon>
                <img src={higienePng} alt="Higiene Personal" style={{ width: '2rem', height: '2rem' }} />
              </ListItemIcon>
              <ListItemText primary="Higiene Personal" />
              {openCategory === 4 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCategory === 4} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subCategories.map(subCategory => (
                  <ListItem key={subCategory.id_subCategory} button sx={{ pl: 4 }}>
                    <ListItemText 
                    onClick={()=>{handleSubCategoryClick(subCategory.id_subCategory)}}
                    primary={subCategory.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            {/* otros */}
            <ListItem button onClick={() => handleClick(5)}>
              <ListItemIcon>
                <img src={otrosPng} alt="otros" style={{ width: '2rem', height: '2rem' }} />
              </ListItemIcon>
              <ListItemText primary="otros" />
              {openCategory === 5 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCategory === 5} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subCategories.map(subCategory => (
                  <ListItem key={subCategory.id_subCategory} button sx={{ pl: 4 }}>
                    <ListItemText
                    onClick={()=>{handleSubCategoryClick(subCategory.id_subCategory)}}
                    primary={subCategory.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default MenuLatMobile;
