import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchArticlesSpecialCategory= async (id_special_category) => {
    const params = new URLSearchParams()
    params.append('id_special_category', id_special_category)
    const url = `${apiUrl}/getAllArticlesBySpecialCategory?${params}`
    console.log('URL', url)
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}

export const fetchSpecialCategories = async () => {
    const url = `${apiUrl}/getAllSpecialCategories`
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response.data);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}