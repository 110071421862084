import React, { createContext, useState } from 'react'
export const Context = createContext()
const Provider = ({ children }) => {
    // LO QUE SE DEFINE
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [articles, setArticles] = useState([])
    const [showMainCarrousel, setShowMainCarrousel] = useState(true)
    const [loguedUser, setLoguedUser] = useState(false)
    const [rol, setRol] = useState('')
    const [arrayProductShop, setArrayProductShop] = useState([])
    const [mailContext, setMailContext] = useState('')
    const testContext = 'Context Exitoso';
    const [allSubCategories, setAllSubCategories] = useState([]);
    const [menuSubCategories, setMenuSubCategories] = useState([]);
    const [profileContext, setProfileContext] = useState({})
    const handleLogOff = () => {
        setRol('')
        setLoguedUser(false)
    }
    //LO QUE SE EXPORTA
    const value = {
        testContext,
        categories, setCategories,
        subCategories, setSubCategories,
        articles, setArticles,
        showMainCarrousel, setShowMainCarrousel,
        loguedUser,
        setLoguedUser,
        arrayProductShop,
        setArrayProductShop,
        rol,
        setRol,
        mailContext,
        setMailContext,
        allSubCategories,
        setAllSubCategories,
        menuSubCategories,
        setMenuSubCategories,
        profileContext,
        setProfileContext,
        handleLogOff
    }
    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}
export { Provider };