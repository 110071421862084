import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@mui/material';
// import { fetchArticlesSpecialCategory } from '../../controllers/specialCategories';
import { fetchArticlesSpecialCategory } from '../../controllers/specialCategories';
import SpecialCategoryCarousel from '../containerCarrousel/SpecialCategoryCarousel';

// export const SpecialCategoriesCatalog = ({id_special_category }) => {
//     const [articles, setArticles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchArticles = async () => {
//             try {
//                 setLoading(true);
//                 const fetchedArticles = await fetchArticlesSpecialCategory(id_special_category);
//                 setArticles(fetchedArticles);
//                 setError(null);
//             } catch (err) {
//                 setError('Error fetching articles');
//                 console.error('Error fetching articles:', err);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchArticles();
//     }, [id_special_category]);

//     if (loading) {
//         return <CircularProgress />;
//     }

//     if (error) {
//         return <p>{error}</p>;
//     }

//     return (
//         <>

//             <Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
//                 {articles.length > 0 ? (
//                     articles.map((product, index) => {
//                         const imageUrl = product?.image?.url || 'default-image-url.jpg';
//                         return (
//                             <Grid item key={index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', justifyContent: 'center', marginLeft: '1%' }}>
//                                 <ProductCard
//                                     product={product}
//                                     imageUrl={imageUrl}
//                                 />
//                             </Grid>
//                         );
//                     })
//                 ) : (
//                     <p>No products available</p>
//                 )}
//             </Grid>
//         </>
//     )
// };
export const SpecialCategoriesCatalog = ({ id_special_category }) => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchArticles = async () => {
        try {
          setLoading(true);
          const fetchedArticles = await fetchArticlesSpecialCategory(id_special_category);
          setArticles(fetchedArticles);
          setError(null);
        } catch (err) {
          setError('Error fetching articles');
          console.error('Error fetching articles:', err);
        } finally {
          setLoading(false);
        }
      };
  
      fetchArticles();
    }, [id_special_category]);
  
    if (loading) {
      return <CircularProgress />;
    }
  
    if (error) {
      return <p>{error}</p>;
    }
  
    return (
    
    <>
    <SpecialCategoryCarousel articles={articles} />
    </>
    );
  };
  