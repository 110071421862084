import * as React from 'react';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ProductCard } from '../utils/ProductCard';
import useMainContext from '../../context/useMainContext';
export const ShopMain = () => {
    const [loading, setLoading] = useState(false);
    const { articles } = useMainContext()
    useEffect(() => {
        console.log('data recibida en ShopMain ', articles)
    }, [articles])

    return (
        <>
            <Grid container sx={{ height: '100%', 
                // border: '2px solid red' 
                }}>

                {
                    articles?.map(item => {
                       
                        return (
                            <Grid sx={{margin:5}}>
                                <ProductCard key={item.id_article} product={item} imageUrl={item?.image?.url || 'default.png'} />
                            </Grid>
                        )
                    })
                }

            </Grid>
        </>
    )
}