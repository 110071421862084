import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCategories = async () => {
    const url = `${apiUrl}/getAllCategory`
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response.data);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}