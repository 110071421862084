import * as React from 'react';
import { useState } from 'react';
import { Box, Button, Grid, TextField, Modal, Typography, Alert, CircularProgress } from '@mui/material';
import useMainContext from '../../context/useMainContext';
import { fetchLogin } from '../../controllers/users';
import RegisterModal from './modalRegister';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Ancho del modal en porcentaje para móviles
  maxWidth: 500, // Máximo ancho del modal
  height: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

export default function LoginModal({ openModalLogin, setOpenModalLogin }) {
  const { setLoguedUser, setRol, setMailContext, profileContext, setProfileContext } = useMainContext();
  const [messageResponse, setMessageResponse] = useState('');
  const [error, setError] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModalRegister, setOpenModalRegister] = useState(false)
  const handleClose = () => setOpenModalLogin(false);

  const handleLogin = async (e) => {

    setLoading(true);
    const data = { username, password };
    try {
      const response = await fetchLogin(data);
      console.log('Respuesta recibida: ', response)
      if (response.user) {
        localStorage.setItem('username', response.user.username);
        localStorage.setItem('rol', response.user.rol);
        if (response.user?.Profile) {
          console.log('se cargo profile context')
          setProfileContext(response.user.Profile)
        }
        setRol(response.user.rol);
        setMailContext(response.user.mail);
        setLoguedUser(true);
        setOpenModalLogin(false);
      }
    } catch (error) {
      const response = error.response;
      if (response.status === 401 || response.status === 404) {
        setError(true);
        setMessageResponse(response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleRegister = () => {
    setOpenModalRegister(true)
    // handleClose()

  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Modal
      open={openModalLogin}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid className='headerFormRegister' item xs={12}>
            <Typography className='tituloFormRegister' variant="h6" component="h2" align="center">
              INICIAR SESION
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="campo1"
              label="Username"
              value={username}
              onChange={(e) => { setUsername(e.target.value); }}
              fullWidth
              margin="normal"
            />
            <TextField
              id="campo2"
              label="Password"
              type='password'
              value={password}
              onChange={(e) => { setPassword(e.target.value); }}
              onKeyDown={handleKeyDown}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                onClick={handleLogin}
                type="submit"
                variant='outlined'
              >
                Iniciar
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant='outlined'>
                Cerrar
              </Button>
            </Grid>
            <Grid item xs={12}>
              {error && <Alert severity="error">{messageResponse}</Alert>}
            </Grid>
          </Grid>
          <Grid sx={{ display: { sm: 'none', xs: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>
            <Button
              onClick={handleRegister}
              type="submit"
              variant='outlined'
            >
              Registrarme
            </Button>
          </Grid>
        </Grid>
        {loading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'rgba(0, 0, 0, 0.5)', // Oscurece el fondo mientras carga
              zIndex: 1300
            }}
          >
            <CircularProgress color="success" />
          </Box>
        )}
        <RegisterModal
          openModalRegister={openModalRegister}
          setOpenModalRegister={setOpenModalRegister}
        />
      </Box>

    </Modal>
  );
}
