import * as React from 'react';
import { useState } from 'react';
import { Box, Button, Grid, Modal, TextField, Alert, LinearProgress, IconButton, Typography, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { AspectRatio } from '@mui/icons-material';
import { fetchCreateUser } from '../../controllers/users';
import CircularProgress from '@mui/material/CircularProgress';
import useMainContext from '../../context/useMainContext';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import '../../Assets/stylesFormRegister.css';
import { margin } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '85%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  overflowY: 'auto' // Agrega scroll vertical cuando el contenido es mayor a la altura definida
};

export default function RegisterModal({ openModalRegister, setOpenModalRegister }) {
  const { rol } = useMainContext();
  const [age, setAge] = useState('');
  const [responseAlert, setResponseAlert] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [phone, setPhone] = useState('')
  const [calle, setCalle] = useState('');
  const [esquina, setEsquina] = useState('');
  const [numeroPuerta, setNumeroPuerta] = useState('');
  const [rolNewUser, setRolNewUser] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    setLoading(true);
    const data = {
      username,
      password,
      mail: email,
      nombre,
      apellido,
      callePrincipal: calle,
      calleEsquina: esquina,
      numeroPuerta,
      phone
    };
    console.log('data register:', data);
    try {
      await fetchCreateUser(data);
      setResponseAlert('success');
      setTimeout(handleCleanInput, 2000); 
    } catch (error) {
      setResponseAlert('error');
    } finally {
      setLoading(false);
    }
  };

  const handleCleanInput = () => {
    setUsername('');
    setPassword('');
    setEmail('');
    setNombre('');
    setApellido('');
    setCalle('');
    setEsquina('');
    setNumeroPuerta('');
    setResponseAlert('');
    setPhone('');
    setOpenModalRegister(false);
  };

  const handleClose = () => setOpenModalRegister(false);

  return (
    <div>
      <Modal
        open={openModalRegister}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid className='headerFormRegister'>
              <h2 className='tituloFormRegister'>FORMULARIO DE REGISTRO</h2>
            </Grid>
            <Grid className='containerFormRegister' sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
              <Grid className='containerInputForm'>
                <TextField
                  id="username"
                  label="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="password"
                  label="Password"
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="nombre"
                  label="Nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="apellido"
                  label="Apellido"
                  value={apellido}
                  onChange={(e) => setApellido(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="telefono"
                  label="Telefono"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="calle"
                  label="Calle"
                  value={calle}
                  onChange={(e) => setCalle(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="esquina"
                  label="Esquina"
                  value={esquina}
                  onChange={(e) => setEsquina(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="numeroPuerta"
                  label="Numero de puerta"
                  value={numeroPuerta}
                  onChange={(e) => setNumeroPuerta(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                {rol === 'Administrador' && (
                  <FormControl style={{ marginTop: '15px' }} fullWidth>
                    <InputLabel id="category-select-label">Seleccione el rol</InputLabel>
                    <Select
                      labelId="category-select-label"
                      id="category-select"
                      value={rolNewUser}
                      label="Categoria"
                      onChange={(e) => setRolNewUser(e.target.value)}
                    >
                      <MenuItem value='Cliente'>
                        Cliente
                      </MenuItem>
                      <MenuItem value='Administrador'>
                        Administrador
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                {responseAlert === 'success' && (
                  <Alert>
                    Se registro con éxito
                  </Alert>
                )}
                {responseAlert === 'error' && (
                  <Alert variant='error'>Hubo un error</Alert>
                )}
              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Grid item>
                <Button onClick={handleRegister} variant='outlined' sx={{ margin: 5, padding: 2 }}>
                  Registrarme
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleClose} variant='outlined' sx={{ margin: 5, paddingX: 6, paddingY: 2 }}>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {loading && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'rgba(0, 0, 0, 0.5)' // Esto oscurece el fondo mientras carga
              }}
            >
              <CircularProgress color="success" />
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
