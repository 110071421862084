import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCreateUser = async (data) => {
    const url = `${apiUrl}/createUser`
    console.log('URL', url)
    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}
export const fetchLogin = async (data) => {
    const url = `${apiUrl}/login`
    console.log('URL', url)
    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}
export const fetchSendMail = async (data) => {
    const url = `${apiUrl}/sendMailConfirmation`
    console.log('URL', url)
    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}