import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography, FormControl, InputLabel, MenuItem, Select,IconButton,  Checkbox, FormControlLabel } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { fetchPostArticle, fetchUpdateArticle, fetchDeleteArticle } from '../../controllers/articles';
import { fetchCategories } from '../../controllers/categories';
import { fetchSubCategories } from '../../controllers/subCategories';
import { fetchSpecialCategories } from '../../controllers/specialCategories';
import ModalAvisos from './ModalAvisos';
import CloseIcon from '@mui/icons-material/Close';
import useMainContext from '../../context/useMainContext';
const apiUrl = process.env.REACT_APP_API_URL;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#228066',
        },
        secondary: {
            main: '#5AB142',
        },
        danger: {
            main: '#EE2F2F'
        }
    },
});

const EditProductModal = ({ open, handleClose, product, handleSave }) => {
    const { setShowMainCarrousel } = useMainContext()
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [stock, setStock] = useState(0);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [specialCategories, setSpecialCategories] = useState([]);
    const [selectedSpecialCategory, setSelectedSpecialCategory] = useState('');
    const [showSpecialCategory, setShowSpecialCategory] = useState(false);
    const [openModalAvisos, setOpenModalAvisos] = useState(false)
    const [message, setMessage] = useState('')
    const [color, setColor] = useState('')
    const [openModal, setOpenModal] = useState(false)

    const handleChangeSelectedCategory = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleChangeSelectedSubCategory = (event) => {
        setSelectedSubCategory(event.target.value);
    };

    const handleSpecialCategoryChange = (event) => {
        setSelectedSpecialCategory(event.target.value);
    };

    const handleShowSpecialCategoryChange = (event) => {
        setShowSpecialCategory(event.target.checked);
        if (!event.target.checked) {
            setSelectedSpecialCategory(null);
        }
    };

    const handleCloseOperation = () => {
        handleClose(false);
        setOpenModalAvisos(false);
    }

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };
    const handleAlert = (message, color) => {
        setOpenModalAvisos(true)
        setMessage(message)
        setColor(color)
    }
    const handleCleanForm = () => {
        setTitle('')
        setDescription('')
        setPrice('')
        setStock(0)
        setImage(null)
        setSelectedCategory(0)
        setSelectedSubCategory('')
        setCategories([])

    }
    const handleUpdate = async () => {
        setLoading(true);
        const updatedProduct = { ...product, title, description, price, image, stock, id_subCategory: selectedSubCategory, id_special_category: showSpecialCategory ? selectedSpecialCategory : null };
        try {
            const response = await fetchUpdateArticle(updatedProduct);
            if (image) {
                console.log('id article', product.id_article)
                const formData = new FormData();
                formData.append('image', image);
                formData.append('id_article', product.id_article);
                await axios.post(`${apiUrl}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }
            setLoading(false);
            handleAlert('Se actualizó el artículo correctamente', 'primary')
        } catch (error) {
            console.log('Hubo un error', error);
            setLoading(false);
        }
    };
    const handleRegister = async () => {
        setLoading(true);
        const updatedProduct = { name: title, description, price, stock, id_subCategory: selectedSubCategory, id_special_category: showSpecialCategory ? selectedSpecialCategory : null };
        console.log('Create product: ', updatedProduct)
        try {
            const response = await fetchPostArticle(updatedProduct)
            console.log('respuesta de artículo: ', response)
            if (image && response?.article) {
                const formData = new FormData();
                formData.append('image', image);
                formData.append('id_article', response.article.id_article);
                const imageResponse = await axios.post(`${apiUrl}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log('respuesta de imagen: ', imageResponse)
                setImage(response.data.url)
            }
            setLoading(false);
            handleAlert('Se registra el articulo correctamente', 'primary')
            handleCleanForm()
            // setShowMainCarrousel(true)
        } catch (error) {
            // setLoading(false);
            // handleAlert(error.response, 'warning')
            console.log('Hubo un error', error);
            setLoading(false);
        } 
        finally {
        //     setLoading(false)
        handleAlert('Se registró el artículo correctamente', 'primary');
        }

    }
    const handleDelete = async () => {
        setLoading(true)
        try {
            await fetchDeleteArticle(product.id_article)
            handleAlert('Se elimino correctamente el producto', 'primary')
            handleCleanForm()
            // setShowMainCarrousel(true)
        } catch (error) {
            console.log('Hubo un error al eliminar el artículo', error)
        } finally {
            setLoading(false)
        }
    }
    const handleGetCategories = async () => {
        setLoading(true);
        try {
            const response = await fetchCategories();
            setCategories(response);
        } catch (error) {
            console.log('error');
        } finally {
            setLoading(false);
        }
    };

    const handleGetSubCategories = async (id_category) => {
        setLoading(true);
        try {
            const response = await fetchSubCategories(id_category);
            setSubCategories(response);
        } catch (error) {
            console.log('error');
        } finally {
            setLoading(false);
        }
    };

    const handleGetSpecialCategories = async () => {
        setLoading(true);
        try {
            const response = await fetchSpecialCategories();
            setSpecialCategories(response);
        } catch (error) {
            console.log('error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setTitle(product?.name || '');
        setDescription(product?.description || '');
        setPrice(product?.price || '');
        setStock(product?.stock || 0);
    }, [product]);

    useEffect(() => {
        if (open) {
            handleGetCategories();
        }
    }, [open]);

    useEffect(() => {
        if (selectedCategory) {
            handleGetSubCategories(selectedCategory);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (open) {
            handleGetSpecialCategories();
        }
    }, [open]);

    return (
        <ThemeProvider theme={theme}>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        {product ?
                            (<Typography id="modal-modal-title" variant="h5" component="h2" gutterBottom>
                                Editar Producto
                            </Typography>) : (
                                <Typography id="modal-modal-title" variant="h5" component="h2" gutterBottom>
                                    Registrar Producto
                                </Typography>
                            )}
                        <IconButton sx={{ marginBottom: 3 }} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>


                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                {product?.image && (
                                    <Box mt={2}>
                                        <img src={product.image.url || image || ''} alt="Product" style={{ width: '100%', height: 'auto' }} />
                                    </Box>
                                )}
                                <input
                                    accept="image/*"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                    id="upload-image"
                                />
                                <label htmlFor="upload-image">
                                    <Button variant="contained" component="span" color="primary">
                                        Cambiar Imagen
                                    </Button>
                                </label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Título"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Descripción"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Precio"
                                        type="number"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Stock"
                                        type="number"
                                        value={stock}
                                        onChange={(e) => setStock(e.target.value)}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="category-select-label">Categoria</InputLabel>
                                        <Select
                                            labelId="category-select-label"
                                            id="category-select"
                                            value={selectedCategory}
                                            label="Categoria"
                                            onChange={handleChangeSelectedCategory}
                                        >
                                            {categories.map((cat) => (
                                                <MenuItem key={cat.id_category} value={cat.id_category}>
                                                    {cat.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="subcategory-select-label">Sub Categoria</InputLabel>
                                        <Select
                                            labelId="subcategory-select-label"
                                            id="subcategory-select"
                                            value={selectedSubCategory}
                                            label="Sub Categoria"
                                            onChange={handleChangeSelectedSubCategory}
                                        >
                                            {subCategories.map((subCat) => (
                                                <MenuItem key={subCat.id_subCategory} value={subCat.id_subCategory}>
                                                    {subCat.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                
                                <Grid container spacing={3}>
                        {/* ... otros campos ... */}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showSpecialCategory}
                                        onChange={handleShowSpecialCategoryChange}
                                        name="showSpecialCategory"
                                        color="primary"
                                    />
                                }
                                label="¿Tiene categoría especial?"
                            />
                        </Grid>
                        {showSpecialCategory && (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="special-category-select-label">Categorías Especiales</InputLabel>
                                    <Select
                                        labelId="special-category-select-label"
                                        id="special-category-select"
                                        value={selectedSpecialCategory}
                                        label="Categoría Especial"
                                        onChange={handleSpecialCategoryChange}
                                    >
                                        {specialCategories.map((specialCat) => (
                                            <MenuItem key={specialCat.id_special_category} value={specialCat.id_special_category}>
                                                {specialCat.category_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        
                    </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="flex-end">
                            {product ? (
                                <Button variant="contained" color="primary" onClick={handleUpdate}>
                                    Guardar
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary" onClick={handleRegister}>
                                    Registrar
                                </Button>
                            )}
                            {product ? (
                                <Button variant="outlined" color="danger" onClick={handleDelete} sx={{ ml: 2 }}>
                                    Eliminar
                                </Button>
                            ) : (false)}
                            <Button variant="outlined" color="secondary" onClick={handleClose} sx={{ ml: 2 }}>
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                    {loading && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 1300
                            }}
                        >
                            <CircularProgress color="success" />
                        </Box>
                    )}
                </Box>
            </Modal>
            <ModalAvisos
                open={openModalAvisos}
                handleClose={ (e) => { handleCloseOperation() }}
                color={color}
                message={message}
            />
        </ThemeProvider>
    );
};

export default EditProductModal;
