import { Box, Container, Grid, Typography, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import footerShapeImage from '../Assets/footer_bg.png';

import {React} from 'react';


const Footer = () => {

  return (
    <>
      <Box
        component="footer"
        sx={{
          color: 'black',
          py: 5,
          boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)',
          backgroundImage: `url(${footerShapeImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} sm={6} md={4} sx={{ textAlign: { sm: 'left' }, mb: { xs: 3, sm: 0 } }}>
              <Link href="" rel="noopener" color="inherit" underline="none" className="letter">
                <Typography
                  className="letter"
                  variant="h3"
                  sx={{ color: '#e85d04', fontFamily: "Lilita One", fontSize: { xs: '2rem', md: '3rem' } }}
                >
                  Lima
                </Typography>
                <Typography
                  className="letter"
                  variant="h3"
                  sx={{
                    color: 'green',
                    marginTop: '-1rem',
                    marginLeft: { xs: '1rem', sm: '2rem', md: '3rem', lg: '5rem' },
                    fontFamily: "Lilita One",
                    fontSize: { xs: '2rem', md: '3rem' }
                  }}
                >
                  Lima
                </Typography>
              </Link>
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
              <Grid container spacing={1} justifyContent="center">
                <Grid item>
                  <Link href="https://www.instagram.com/limalimasupermercado?igsh=MWw3MDg2eHZ2YmN5NA==" target="_blank" rel="noopener" color="inherit" underline="none">
                    <InstagramIcon fontSize="small" sx={{ backgroundColor: '#ffff', color: 'green', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)', fontSize: { xs: '1.5rem', md: '2rem' } }} />
                  </Link>
                </Grid>

                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <Link href="https://wa.me/+59896930073" target="_blank" rel="noopener" color="inherit" underline="none">
                    <WhatsAppIcon fontSize="small" sx={{ backgroundColor: '#ffff', color: 'green', border: '3px solid #ffff', borderRadius: '5px', marginRight: '8px', marginTop:'1.5%', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)', fontSize: { xs: '1.5rem', md: '2rem' } }} />
                    <span style={{ color: 'green', fontSize: '1.5rem', display: 'inline-block', verticalAlign: 'middle', marginTop: '-1.8rem' }}>096930073</span>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ marginTop: '2rem' }}>
            <Typography
              variant="caption"
              align="center"
              sx={{
                background: 'linear-gradient(to top, #27477A, #4B0082)',
                color: 'white',
                padding: '2px 4px',
                borderRadius: '2px',
                fontSize: '0.8rem',
                '&:hover': {
                    cursor: 'default'
                }
              }}
            >
              Copyright 2024 - LimaLima by JAR Software
            </Typography>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;