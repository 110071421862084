import React, { useState } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/material/Box';
import EditProductModal from './EditProduct';
import useMainContext from '../../context/useMainContext';

import { fetchSubCategories } from '../../controllers/subCategories';
 
export function SubCategoryCard({ product, imageUrl, onClick }) {

    const apiUrl = process.env.REACT_APP_API_URL;
    const rol = localStorage.getItem('rol');
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [open, setOpen] = useState(false);
    const [productEdit, setProductEdit] = useState(false);
    const { setArticles, subCategories, setSubCategories } = useMainContext();
    // const [subCategories, setSubCategories] = useState([])
    const { showMainCarrousel, setShowMainCarrousel} = useMainContext();
 

    const handleClick = async () => {
        if (onClick) {
            onClick(product.id_subCategory);
            try {
                const response = await fetchSubCategories(product.id_category);
                setSubCategories(response);
                console.log('Sub Categorias', response);
                setShowMainCarrousel(false);
            } catch (error) {
                console.error('Error fetching subcategories:', error);
            }
        }
       
    };
 
 
    // console.log("Subcategory passed to SubCategoryCard:", product);
    // console.log("Image URL passed to SubCategoryCard:", imageUrl);
 
    return (
        <>
            <Card sx={{ width:{xs:'70%', lg:'85%'}, maxHeight: 320, boxShadow: 'lg', marginLeft: {xs:'8%'} }} onClick={handleClick}>
                <CardOverflow>
                    <AspectRatio sx={{ minWidth: 200 }}>
                        <img
                            src={imageUrl}
                            loading="lazy"
                            alt={product?.description || 'Producto'}
                        />
                    </AspectRatio>
                </CardOverflow>
                <CardContent>
                    <Typography level="title-lg" sx={{ mt: 1, fontWeight: 'xl' }}>{product.name}</Typography>
                </CardContent>
            </Card>
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width:'100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1300
                    }}
                >
                    <CircularProgress color="success" />
                </Box>
            )}
            <EditProductModal
               
                // open={open}
                // handleClose={handleClose}
                product={productEdit}
                // handleSave={handleSave}
            />
        </>
    );
}