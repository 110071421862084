import './App.css';
import { Index } from './components';
import { Provider } from './context/mainContext';
function App() {
  return (
    <>
      <Provider>
        <Index />
      </Provider>
    </>
  );
}

export default App;
