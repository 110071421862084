import React from 'react';
import { Box, Button, Card, CardContent, Typography, Modal, Alert } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#228066',
        },
        success: {
            main: '#5AB142',
        },
    },
});

const contentStyle = {
    flex: '1 1 auto',
    overflowY: 'auto',
    mb: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};

const footerStyle = {
    display: 'flex',
    justifyContent: 'center',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'background.paper',
    padding: '16px',
};

const ModalAvisos = ({ open, handleClose, message, color }) => {
    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={style}>
                    <CardContent sx={contentStyle}>
                        <Typography variant="h5" component="div" gutterBottom>
                            Notificación
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Alert color={color}>
                                {message}
                            </Alert>
                        </Box>
                    </CardContent>
                    <Box sx={footerStyle}>
                        <Button
                            variant="contained"
                            color={color}
                            onClick={handleClose}
                        >
                            Aceptar
                        </Button>
                    </Box>
                </Card>
            </Modal>
        </ThemeProvider>
    );
};

export default ModalAvisos;
