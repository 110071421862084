import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoginModal from '../containerUser/modalLogin';
import { Typography, Badge, Menu, MenuItem } from "@mui/material";
import PrincipalHeader from './principalHeader';
import Categories from './categories';
import MenuLatMobile from '../ShopNavigator/MenuLatMobile';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useMainContext from '../../context/useMainContext';
import PreOrderModal from '../utils/PreOrderModal';
const CustomGrid = styled(Grid)({
  height: '50%',
});

export default function MainHeader() {
  const { arrayProductShop, loguedUser, handleLogOff } = useMainContext()
  const [openModalLogin, setOpenModalLogin] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalPreOrder, setOpenModalPreOrder] = useState(false)
  const openMenu = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '275px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid container >
        <Grid
          container
          sx={{
            display: { xs: 'flex', sm: 'none' },
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 1
          }}
        >
          <Grid item>
            <MenuLatMobile />
          </Grid>

          <Grid item sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Badge
              badgeContent={arrayProductShop.length}
              color="error"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ShoppingCartIcon
                onClick={() => {
                  if (arrayProductShop.length > 0) {
                    setOpenModalPreOrder(true)
                  }
                }
                }
              />
            </Badge>
            <AccountCircleIcon
              sx={{ cursor: 'pointer' }}
              onClick={!loguedUser ? () => { setOpenModalLogin(true) } : handleMenuOpen}
            // onClick={() => {setOpenModalLogin(true)}}
            // onClick={handleMenuOpen}
            />
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              sx={{ mt: '1.5rem' }}
            >
              <MenuItem onClick={() => {
                handleMenuClose()
                handleLogOff()
              }}>
                Cerrar Sesion
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <PrincipalHeader item xs={12} />
      </Grid>
      <Grid sx={{ display: { xs: 'none', sm: 'none' } }} item>
        <Typography variant="h1" sx={{ color: '#228066', fontSize: '2.5rem', fontWeight: 'bold', paddingLeft: '42.5%' }}>
          Categorías
        </Typography>
      </Grid>
      <Grid container sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <Categories
          item
          xs={12}
        />
      </Grid>

      <LoginModal
        openModalLogin={openModalLogin}
        setOpenModalLogin={setOpenModalLogin}
      />
      <PreOrderModal
        open={openModalPreOrder}
        handleClose={() => { setOpenModalPreOrder(false) }} />
    </Box>
  );
}