import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Typography, Grid, IconButton, Modal, TableContainer, TableCell, TableBody, TableHead, TableRow, Table, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useMainContext from '../../context/useMainContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchSendMail } from '../../controllers/users';
import ModalAvisos from '../utils/ModalAvisos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#228066',
        },
        success: {
            main: '#5AB142',
        },
    },
});

const contentStyle = {
    flex: '1 1 auto',
    overflowY: 'auto',
    mb: 3,
};

const footerStyle = {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'background.paper',
    padding: '16px',
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
};

const PreOrderModal = ({ open, handleClose }) => {
    const { arrayProductShop, setArrayProductShop, mailContext, loguedUser, profileContext } = useMainContext();
    const [arrayProductLocal, setArrayProductLocal] = useState([]);
    const [color, setColor] = useState('');
    const [message, setMessage] = useState('');
    const [openModalAvisos, setOpenModalAvisos] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shippingCost, setShippingCost] = useState(100)
    const [form, setForm] = useState(false)
    // const [totalCost, setTotalCost] = useState(0)
    const handleRemoveProduct = (productId) => {
        const updatedArray = arrayProductShop.filter(product => product.id_article !== productId);
        setArrayProductShop(updatedArray);
    };

    const getTotal = () => {
        const productTotal = arrayProductLocal.reduce((total, product) => {
            return total + (product.price * (product.quantity || 1));
        }, 0);
        return (productTotal + shippingCost).toFixed(2);
    };

    const handleAlert = (message, color) => {
        setOpenModalAvisos(true);
        setMessage(message);
        setColor(color);
    };

    const sendPurchase = async () => {

        if (loguedUser) {
            console.log('profile', profileContext)
            const formDataFromProfile = {
                firstName: profileContext.name,
                lastName: profileContext.lastName,
                address: `${profileContext.mainStreet} ${profileContext.cournerStreet} ${profileContext.doorNumber}`,
                phone: profileContext.phone,
                email: mailContext
            };

            setFormData(formDataFromProfile);

            const data = {
                to: mailContext,
                products: arrayProductLocal,
                shippingCost,
                totalCost: getTotal(),
                formData: formDataFromProfile
            };
            console.log('data antes de enviar', data)
            try {
                setLoading(true);
                const response = await fetchSendMail(data);
                console.log('responseMail', response);
                handleAlert('Se ha enviado un mail con la confirmacion del pedido', 'primary');
                setArrayProductShop([]);
                resetForm()
                setForm(false)
            } catch (error) {
                console.log('hubo un error');
                handleAlert('hubo un error', 'warning');
            } finally {
                setLoading(false);
            }
        } else {
            setForm(true)
            if (formData.email !== '') {
                console.log('Se envian los datos', formData)
                const data = {
                    to: formData.email,
                    products: arrayProductLocal,
                    shippingCost,
                    totalCost: getTotal(),
                    formData
                };
                try {
                    setLoading(true)
                    const response = await fetchSendMail(data);
                    console.log('responseMail', response);
                    handleAlert('Se ha enviado un mail con la confirmacion del pedido', 'primary');
                    setArrayProductShop([]);
                    resetForm()
                    setForm(false)
                } catch (error) {
                    console.log('hubo un error', error);
                    handleAlert('hubo un error', 'warning');
                } finally {
                    setLoading(false);
                }
            }
            console.log('no hay usuario logueado')
        }

    };
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        phone: '',
        email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const resetForm = () => {
        setFormData({
            firstName: '',
            lastName: '',
            address: '',
            phone: '',
            email: ''
        });
    };
    const handleQuantityChange = (productId, newQuantity) => {
        setArrayProductLocal(prevArray =>
            prevArray.map(product =>
                product.id_article === productId
                    ? { ...product, quantity: newQuantity }
                    : product
            )
        );
    };
    useEffect(() => {
        if (open) {
            console.log('Se abre modal de pre compra: ', profileContext)
        }
    }, [open])

    useEffect(() => {
        const newArrayProduct = arrayProductShop.map(product => ({
            ...product,
            quantity: 1
        }));
        console.log('nuevo array', newArrayProduct);
        setArrayProductLocal(newArrayProduct);
    }, [arrayProductShop]);
    useEffect(() => {
        const total = getTotal();
        if (arrayProductLocal.length < 1) {
            handleClose()
        }
        if (total > 1500) {
            console.log('ingreso al if')
            setShippingCost(0);
        } else {
            // Optional: reset shipping cost if total is below or equal to 1500
            setShippingCost(100);
        }
    }, [arrayProductLocal]);

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={style}>
                    <CardContent sx={contentStyle}>

                        {form ? (
                            <Grid>
                                {/* CONTENIDO DE FORMULARIO SIN REGISTRO */}
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Typography variant="h4" component="div" gutterBottom>
                                        COMPLETE FORMULARIO DE CONTACTO
                                    </Typography>
                                    <IconButton onClick={() => { setForm(false) }}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid container spacing={2} marginTop={5}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Nombre"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Apellido"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Dirección"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Teléfono"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                            type="tel"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Correo Electrónico"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                            type="email"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (

                            <Grid>
                                {/* CONTENIDO DE PRE COMPRA */}
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Typography variant="h3" component="div" gutterBottom>
                                        Pre Orden de Compra
                                    </Typography>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                <Grid container spacing={2}>
                                    <TableContainer sx={{ overflowX: 'auto' }}>
                                        <Table sx={{ minWidth: { xs: '100%', sm: 650 } }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Producto</TableCell>
                                                    <TableCell align="right">Cantidad</TableCell>
                                                    <TableCell align="right">Precio</TableCell>
                                                    <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Gestion</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {arrayProductLocal.map((row) => (
                                                    <TableRow
                                                        key={row.id_article}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="right" sx={{ width: '20%' }}>
                                                            <TextField
                                                                type='number'
                                                                value={row.quantity || 1}
                                                                onChange={(e) => handleQuantityChange(row.id_article, Number(e.target.value))}
                                                                inputProps={{ min: 1 }}
                                                                sx={{ maxWidth: '60px' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">${row.price}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton onClick={() => handleRemoveProduct(row.id_article)} color="error">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Box display="flex" justifyContent="space-between" mt={3}>
                                    <Typography variant="h6">Compras mayores a $1500 envio gratis</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mt={3}>
                                    <Typography variant="h6">Costo de envio:</Typography>
                                    <Typography variant="h6">${shippingCost}</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mt={3}>
                                    <Typography variant="h6">Total:</Typography>
                                    <Typography variant="h6">{`$${getTotal()}`}</Typography>
                                </Box>

                                <Grid item xs={15} sm={15} md={10} lg={10}>
                                    <Grid container alignItems="center">

                                        <Grid sx={{
                                            display: 'flex',
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            background: 'orange',
                                            color: 'white',
                                            fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem', lg: '1rem' },
                                            borderRadius: '7px',
                                            border: '1',
                                            padding: { xs: '5%', md: '50%', lg: '2%' },
                                            marginLeft: { xs: '10%', md: '50%', lg: '37%' },
                                            marginTop: { xs: '10%', md: '50%', lg: '2%' },
                                            '&:hover': {
                                                backgroundColor: 'white',
                                                color: 'black',
                                                cursor: 'default'
                                            }
                                        }}>
                                            <Grid variant="h2">EL PAGO SE REALIZA AL MOMENTO DE RECIBIR EL PEDIDO, EN EFECTIVO</Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        )}





                    </CardContent>

                    <Box sx={footerStyle}>
                        <Button
                            onClick={sendPurchase}
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                        >
                            Confirmar Compra
                        </Button>
                    </Box>
                    {loading && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 1300
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </Card>
            </Modal>
            <ModalAvisos
                open={openModalAvisos}
                handleClose={() => { setOpenModalAvisos(false) }}
                color={color}
                message={message}
            />
        </ThemeProvider>
    );
};

export default PreOrderModal;
