import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchSubCategories = async (id_category) => {
    const params = new URLSearchParams()
    params.append('id_category', id_category)
    const url = `${apiUrl}/getAllSubCategory?${params}`
    console.log('URL', url)
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}


export const fetchAllSubCategories = async () => {
    const url = `${apiUrl}/getAllSubCategories`;
    console.log('URL', url);
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        console.log('respuesta fetch, con Sub categorias : ', response.data);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error);
        throw error;
    }
};
export const fetchPostSubCategories = async (data) => {
    const url = `${apiUrl}/postsubCategory`;
    console.log('URL', url);
    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error);
        throw error;
    }
};
export const fetchDeleteSubCategories = async (id_subCategory) => {
    const params = new URLSearchParams
    params.append('id_subCategory', id_subCategory)
    const url = `${apiUrl}/deleteSubCategory?${params}`;
    console.log('URL', url);
    try {
        const response = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error);
        throw error;
    }
};