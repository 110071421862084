import { Grid } from "@mui/material";

export const Section = () => {

    return (
        <>
            {/* <ThemeProvider theme={theme}> */}
            <Grid container className='items' spacing={2} sx={{ marginTop: '2rem', marginLeft: { xs: '2%', sm: '1%', md: '50%', lg: '6%' } }} alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container alignItems="center">
                        
                        <Grid sx={{ 
                            // marginLeft: '0.7rem',
                            background:'grey',
                            color:'white',
                            fontSize: "1rem",
                            borderRadius: '7px',
                            border:'1',
                            padding:'1rem',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'grey',
                                cursor: 'default'
                              }
                              }}>
                            <Grid variant="h1">COMPRÁ ONLINE Y PAGÁ EN LA ENTREGA</Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container alignItems="center">
                      
                        <Grid sx={{ 
                            background:'grey',
                            color:'white',
                            fontSize: "1rem",
                            borderRadius: '7px',
                            border:'1',
                            padding:'1rem',
                            marginLeft: { xs: '10%', md: '50%', lg: '5%' },
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'grey',
                                cursor: 'default'
                            }
                             }}>
                            <Grid variant="h2">TODOS LOS MÉTODOS DE PAGO</Grid>
                           
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container alignItems="center">
                       
                        <Grid sx={{
                            //  marginLeft: '4rem'
                            background:'grey',
                            color:'white',
                            fontSize: "1rem",
                            borderRadius: '7px',
                            border:'1',
                            padding:'1rem',
                            marginLeft: { xs: '5%', sm: '50%', md: '50%', lg: '5%' },
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'grey',
                                cursor: 'default'
                            }
                              }}>
                            <Grid variant="h2">ENVÍOS EN EL DÍA O COORDINADOS</Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* </ThemeProvider> */}
            

        </>
    )
}