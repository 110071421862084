import * as React from 'react';
import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, Select, Menu, MenuItem, IconButton, Typography, Button } from '@mui/material';
import Box from '@mui/material/Box';
import alimentosPng from '../../Assets/alimentos.png'
import bebidasPng from '../../Assets/refrescos.png'
import higienePng from '../../Assets/higiene-personal.png'
import limpiezaPng from '../../Assets/limpieza.png'
import otrosPng from '../../Assets/otros.png'
import useMainContext from '../../context/useMainContext';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchCategories } from '../../controllers/categories';
import { fetchSubCategories } from '../../controllers/subCategories';
import { fetchArticlesBySubCategory } from '../../controllers/articles'
import { fetchAllSubCategories } from '../../controllers/subCategories';

// Define el tema personalizado con un color principal personalizado
const theme = createTheme({
    palette: {
        primary: {
            main: '#ff0000', // Define tu color principal aquí
        },
    },
});

export default function Categories() {
    const { testContext, categories, setCategories, setShowMainCarrousel, subCategories, setSubCategories, setArticles, setAllSubCategories, setMenuSubCategories } = useMainContext()
    const [loading, setLoading] = useState(false);

    const handleSubCategoryClick = async (id_subCategory) => {
        setLoading(true);
        try {
          const articles = await fetchArticlesBySubCategory(id_subCategory);
          setArticles(articles);
          setShowMainCarrousel(false)
        } catch (error) {
          console.log('Error fetching articles:', error);
        } finally {
          setLoading(false);
        }
      };
    const handleGetSubCategories = async (id_category) => {
        setLoading(true)
        try {
            const response = await fetchSubCategories(id_category)
            setSubCategories(response)
            console.log('Sub Categorias', response)
        } catch (error) {
            console.log('hubo error', error)
        } finally {
            setLoading(false)
        }


    }
    const handleArticles = async (id_subCategory) => {
        setLoading(true)
        try {
            const response = await fetchArticlesBySubCategory(id_subCategory)
            setArticles(response)
        } catch (error) {
            console.log('Hubo un error', error)
        } finally {
            setLoading(false)
        }
    }
    const handleClick = (id_category) => {
        setLoading(true)
        try {
            setShowMainCarrousel(false)
            handleGetSubCategories(id_category)
        } catch (error) {

        } finally {
            setLoading(false)
        }

    }

    const handleGetAllSubCategories = async () => {
        try {
            const { subCategories } = await fetchAllSubCategories();
            setAllSubCategories(subCategories);
            setMenuSubCategories(subCategories);
        } catch (error) {
            console.log('Hubo un error', error);
        }
    };
    
    useEffect(() => {
        handleGetAllSubCategories();
    }, []);
 
    useEffect(() => {
        if (subCategories?.length > 0) {
            console.log('Sub Categorias estado', subCategories);
            handleArticles(subCategories[0].id_subCategory)
        }
    }, [subCategories]);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ height: '100%' }}>
                <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                    <nav>
                        <ul class="menu">
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleClick(2) }}
                                    class="iconTransform" width="7rem" height="7rem">
                                    <image href={alimentosPng} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">ALIMENTOS</a>
                                    <ul class="submenu">
                                        <li onClick={() => handleSubCategoryClick(5)}><a class="textoHover" href="#">Enlatados</a></li>
                                        <li onClick={() => handleSubCategoryClick(6)}><a class="textoHover" href="#">Dulces</a></li>
                                        <li onClick={() => handleSubCategoryClick(7)}><a class="textoHover" href="#">General</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleClick(1) }}
                                    class="iconTransform" width="7rem" height="7rem">
                                    <image href={bebidasPng} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">BEBIDAS</a>
                                    <ul class="submenu">
                                        <li onClick={() => handleSubCategoryClick(1)}><a class="textoHover" href="#">Refrescos</a></li>
                                        <li onClick={() => handleSubCategoryClick(2)}><a class="textoHover" href="#">Alcohol</a></li>
                                        <li onClick={() => handleSubCategoryClick(3)}><a class="textoHover" href="#">Agua</a></li>
                                        <li onClick={() => handleSubCategoryClick(4)}><a class="textoHover" href="#">Energizantes</a></li>
                                        <li onClick={() => handleSubCategoryClick(5)}><a class="textoHover" href="#">Jugo</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleClick(4) }}
                                    class="iconTransform" width="7rem" height="7rem">
                                    <image href={limpiezaPng} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">LIMPIEZA</a>
                                    <ul class="submenu">
                                        <li onClick={() => handleSubCategoryClick(8)}><a class="textoHover" href="#">Baño</a></li>
                                        <li onClick={() => handleSubCategoryClick(9)}><a class="textoHover" href="#">Cocina</a></li>
                                        <li onClick={() => handleSubCategoryClick(10)}><a class="textoHover" href="#">Casa</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleClick(3) }}
                                    class="iconTransform" width="7rem" height="7rem">
                                    <image href={higienePng} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">HIGIENE PERSONAL</a>
                                    {/* <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul> */}
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleClick(5) }}
                                    class="iconTransform" width="7rem" height="7rem">
                                    <image href={otrosPng} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">OTROS</a>
                                    <ul class="submenu">
                                        <li onClick={() => handleSubCategoryClick(11)}><a class="textoHover" href="#">Mascotas</a></li>
                                        <li onClick={() => handleSubCategoryClick(12)}><a class="textoHover" href="#">Tecnología</a></li>
                                        <li onClick={() => handleSubCategoryClick(13)}><a class="textoHover" href="#">Papelería</a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </Grid>
                {loading && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bgcolor: 'rgba(0, 0, 0, 0.5)' // Esto oscurece el fondo mientras carga
                        }}
                    >
                        <CircularProgress color="success" />
                    </Box>
                )}
            </Box>
        </ThemeProvider>
    );
}


