import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { fetchAllSubCategories } from '../../controllers/subCategories';
import SubCatCarrousel from '../containerCarrousel/subCatCarrousel';
import { fetchArticlesBySubCategory } from '../../controllers/articles';
import useMainContext from '../../context/useMainContext';
import { fetchSubCategories } from '../../controllers/subCategories';

export const SubCategories = () => {
    const [subCatImages, setSubCatImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [subCategories, setSubCategories] = useState([])

    const { setArticles, setMenuSubCategories 
    } = useMainContext();

    const handleGetAllSubCategories = async () => {
        setLoading(true);
        
        try {
            const { subCategories, subCatImages } = await fetchAllSubCategories();
            setSubCategories(subCategories);
            setSubCatImages(subCatImages);

            console.log('Sub Categorias', subCategories);
            console.log('Sub Cat Images', subCatImages);

        } catch (error) {
            console.log('Hubo un error', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleGetAllSubCategories();
    }, []);

    const handleCategoryClick = async (id_subCategory, id_category) => {
        try {
            const articlesResponse = await fetchArticlesBySubCategory(id_subCategory);
            setArticles(articlesResponse);
            
            const newSubCategories = await fetchSubCategories(id_category);
            setMenuSubCategories(newSubCategories);
        } catch (error) {
            console.log('Hubo un error: ', error);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <p>Hubo un error al cargar las subcategorías.</p>;
    }

    return (
    
        <SubCatCarrousel subCategories={subCategories} subCatImages={subCatImages} onCategoryClick={handleCategoryClick} />
    );
};