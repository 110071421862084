import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography, FormControl, InputLabel, MenuItem, Select, IconButton, Alert } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchCategories } from '../../controllers/categories';
import { fetchPostSubCategories } from '../../controllers/subCategories'
import ModalAvisos from '../utils/ModalAvisos';
import CloseIcon from '@mui/icons-material/Close';
import TableSubCategories from './TableSubCategories';
import MenuIcon from '@mui/icons-material/Menu';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    overflowY: 'auto'
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#228066',
        },
        secondary: {
            main: '#5AB142',
        },
        danger: {
            main: '#EE2F2F'
        }
    },
});

const ModalCategories = ({ open, handleClose }) => {
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [categories, setCategories] = useState([]);
    const [openModalAvisos, setOpenModalAvisos] = useState(false)
    const [message, setMessage] = useState('')
    const [color, setColor] = useState('')
    const [openDrawer, setOpenDrawer] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [alert, setAlert] = useState(false)
    const [categoryDelete, setCategoryDelete] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [size, setSize] = useState(0)
    const handleChangeSelectedCategory = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleGetCategories = async () => {
        setLoading(true);
        try {
            const response = await fetchCategories();
            setCategories(response);
        } catch (error) {
            console.log('error');
        } finally {
            setLoading(false);
        }
    };
    const handleCreateSubCategory = async () => {
        const data = {
            name: title,
            id_category: selectedCategory
        }
        console.log('data', data)
        try {
            const response = await fetchPostSubCategories(data)
            console.log('responseAdd', response)
            setAlert(true)
            handleGetCategories()
            setTimeout(() => {
                setTitle('')
                setSelectedCategory('')
                setAlert(false)
            }, 2500);
        } catch (error) {
            console.log('Hubo un error', error)
        }
    }
    useEffect(() => {
        if (open) {
            setTitle('')
            setSelectedCategory('')
            setOpenDrawer(false)
            handleGetCategories();
        }
    }, [open]);
    useEffect(() => {
        if (categoryDelete) {
            setTimeout(() => {
                setTitle('')

                setCategoryDelete(false)
            }, 2500);
        }
    }, [categoryDelete])
    useEffect(() => {
        setDisabled(!(title && selectedCategory)); // Deshabilitar si title o selectedCategory son vacíos
    }, [title, selectedCategory]);
    useEffect(() => {
        if (openDrawer) {
            setSize(9)
        } else {
            setSize(12)
        }
    }, [openDrawer])

    return (
        <ThemeProvider theme={theme}>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography id="modal-modal-title" variant="h5" component="h2" gutterBottom>
                            Gestión de Categorías
                        </Typography>
                        <IconButton sx={{ marginBottom: 3 }} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>

                    </Grid>
                    <Grid container spacing={3} sx={{ height: '90%' }}>
                        <Grid item xs={12} sm={size} sx={{ height: '100%' }}>
                            <TableSubCategories
                                openDrawer={openDrawer}
                                setOpenDrawer={setOpenDrawer}
                                setRefresh={setRefresh}
                                refresh={refresh}
                                categoryDelete={categoryDelete}
                                setCategoryDelete={setCategoryDelete}
                            />

                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ height: '85%', display: openDrawer ? 'block' : 'none' }}>
                            <Box
                                sx={{
                                    bgcolor: 'background.paper',
                                    p: 2,
                                    borderRadius: '8px',
                                    boxShadow: 3,
                                    height: '100%',
                                    overflowY: 'auto'
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="Nueva sub categoría"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    color="primary"
                                />
                                <FormControl sx={{ marginTop: '10px' }} fullWidth>
                                    <InputLabel id="category-select-label">Categoría</InputLabel>
                                    <Select
                                        labelId="category-select-label"
                                        id="category-select"
                                        value={selectedCategory}
                                        label="Categoría"
                                        onChange={handleChangeSelectedCategory}
                                    >
                                        {categories.map((cat) => (
                                            <MenuItem key={cat.id_category} value={cat.id_category}>
                                                {cat.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} display="flex" justifyContent="space-between" marginTop='10px'>
                            <Grid>
                                {alert ? (
                                    <Alert>
                                        Se registra la categoria con éxito
                                    </Alert>
                                ) : (false)}
                                {categoryDelete ? (
                                    <Alert>
                                        Se elimina la categoria con éxito
                                    </Alert>
                                ) : (false)}
                            </Grid>
                            <Grid>
                                <Button
                                   
                                   disabled={disabled}
                                    onClick={handleCreateSubCategory}
                                    variant="contained" color="primary">
                                    Guardar
                                </Button>
                            </Grid>

                        </Grid>

                    </Grid>
                    {loading && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 1300
                            }}
                        >
                            <CircularProgress color="success" />
                        </Box>
                    )}
                </Box>
            </Modal>
            <ModalAvisos
                open={openModalAvisos}
                handleClose={() => setOpenModalAvisos(false)}
                color={color}
                message={message}
            />
        </ThemeProvider>
    );
};

export default ModalCategories;
