import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { fetchAllSubCategories, fetchDeleteSubCategories } from '../../controllers/subCategories';
import { TextField, IconButton, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
export default function TableSubCategories({ openDrawer, setOpenDrawer, refresh, setRefresh, setCategoryDelete }) {
    const [subCategories, setSubCategories] = useState([]);
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('')
    const handleGetSubCategories = async () => {
        try {
            const response = await fetchAllSubCategories();
            console.log('Se obtuvo', response);
            setSubCategories(response.subCategories);
        } catch (error) {
            console.log('Hubo un error', error);
        }
    };

    const handleDeleteSubCategory = async (id_subCategory) => {
        try {
            await fetchDeleteSubCategories(id_subCategory)
            handleGetSubCategories()
            setCategoryDelete(true)
            setSearch('')
        } catch (error) {
            console.log('Hubo un error al eliminar', error)
        }
    }
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleSearch = (search) => {
        setSearch(search)
        filtrar(search);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const filtrar = (search) => {
        var resultadosBusqueda = subCategories.filter((elemento) => {
            if (elemento.name.toString().toLowerCase().includes(search.toLowerCase())) {
                return elemento;
            }
        });
        setSubCategories(resultadosBusqueda);
    }
    useEffect(() => {
        if (search == '') {
            handleGetSubCategories()
        }
    }, [search])
    useEffect(() => {
        handleGetSubCategories();
    }, []);
    useEffect(() => {
        if (refresh) {
            handleGetSubCategories()
            setRefresh(false)
        }
    }, [refresh])

    return (
        <Paper>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell align="right">
                                <TextField

                                    label="Buscador.."
                                    value={search}
                                    onChange={(e) => { handleSearch(e.target.value) }}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    edge="start"
                                    color="inherit"

                                    onClick={toggleDrawer}
                                >
                                    <AddIcon />
                                </IconButton>
                                {/* <Button variant="outlined" color="primary">
                                    Nueva
                                </Button> */}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subCategories
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell>
                                        <DeleteForeverIcon
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => { handleDeleteSubCategory(row.id_subCategory) }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={subCategories.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
