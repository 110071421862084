import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchArticlesBySubCategory = async (id_subCategory) => {
    const params = new URLSearchParams()
    params.append('id_subCategory', id_subCategory)
    const url = `${apiUrl}/getAllArticles?${params}`
    console.log('URL', url)
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}
export const fetchArticlesBySearch = async (search) => {
    const params = new URLSearchParams()
    params.append('search', search)
    const url = `${apiUrl}/getArticlesBySearch?${params}`
    console.log('URL', url)
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}

export const fetchUpdateArticle = async (data) => {
    const url = `${apiUrl}/updateArticle/${data.id_article}`
    console.log('URL', url)
    try {
        const response = await axios.patch(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}

export const fetchPostArticle = async (data) => {
    const url = `${apiUrl}/postArticle`
    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}

export const fetchDeleteArticle = async (id) => {
    const url = `${apiUrl}/deleteArticle/${id}`
    try {
        const response = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}