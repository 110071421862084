import * as React from 'react';
import { useState, useEffect } from 'react';
import { Grid, TextField, Stack, Badge, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import '../../Assets/styles.css'
import '../../Assets/stylesFormRegister.css'
import useMainContext from '../../context/useMainContext';
import { fetchArticlesBySearch } from '../../controllers/articles'
import logo from '../../Assets/Logo.jpg'
import perfilIcon from '../../Assets/perfil.png'
import carritoIcon from '../../Assets/carrito-de-compras.png'
import RegisterModal from '../containerUser/modalRegister';
import LoginModal from '../containerUser/modalLogin';
import EditProductModal from '../utils/EditProduct';
import PreOrderModal from '../utils/PreOrderModal'
import ModalCategories from '../adminComponent/ModalCategories';
import CategoryIcon from '@mui/icons-material/Category';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
const theme = createTheme({
    palette: {
        primary: {
            main: '#228066', // Define tu color principal aquí
        },
        success: {
            main: '#5AB142',
        },
    },
});
export default function PrincipalHeader() {
    const { setShowMainCarrousel, setArticles, loguedUser, setLoguedUser, arrayProductShop,
        setArrayProductShop, setRol, rol } = useMainContext()
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [openModalRegister, setOpenModalRegister] = useState(false)
    const [openModalLogin, setOpenModalLogin] = useState(false)
    const [open, setOpen] = useState(false);
    const [openModalPreOrder, setOpenModalPreOrder] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModalCategories, setOpenModalCategories] = useState(false)
    const openMenu = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleClick = () => {
        setShowMainCarrousel(true)
    }
    const handleSearch = async () => {
        setLoading(true)
        try {
            const response = await fetchArticlesBySearch(search)
            console.log('respuesta de obtener articulos por busqueda')
            setArticles(response)
            setShowMainCarrousel(false)
            setLoading(false)
        } catch (error) {
            console.log('Hubo un error', error)
            setLoading(false)
        }
    }
    const handleOpenModalProduct = () => {
        setOpen(true)
    }
    const handleCloseModalProduct = () => {
        setOpen(false)
    }
    const handleLogOff = () => {
        setRol('')
        setLoguedUser(false)
    }

    useEffect(() => {
        if (search.trim() !== '') {
            handleSearch();
        }
    }, [search]);
    useEffect(() => {
        console.log('Logued User', loguedUser)
    }, [loguedUser]);
    return (
        <>
            <ThemeProvider theme={theme}>
                {loguedUser == true && rol == 'Administrador' && (
                    <Grid container sx={{ display: 'flex', justifyContent: 'right', marginRight: '5%' }}>
                        ADMINISTRADOR
                    </Grid>
                )}


                <Grid container sx={{ display: 'flex', justifyContent: 'center', marginLeft: '5%', width: '100%' }}>
                   
                    <Grid item xs={6} sm={6} md={2}>
                        <img
                            style={{ cursor: 'pointer' }}
                            onClick={handleClick}
                            width={'100%'}
                            src={logo}
                            alt={'Erebil'}
                            loading="lazy"
                            height={'120rem'}
                        />
                    </Grid>

                   
                    <Grid item xs={12} sm={6} md={1} sx={{ display: { xs: 'block', sm: 'none' } }}>
                       
                    </Grid>

                   
                    <Grid item xs={12} sm={6} md={7}>
                        <TextField
                            sx={{ width: '80%', marginTop: '2rem' }}
                            label="Buscador.."
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between', marginTop: '1rem', }}>
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center' }} className='containerForPerfil'>
                                <Grid
                                    item
                                    sm={3}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginRight: '10px',
                                    }}
                                    className='item iconHover'
                                >
                                    <IconButton onClick={handleMenuOpen} sx={{ padding: 0 }}>
                                        <Box
                                            component="img"
                                            src={perfilIcon}
                                            alt="Perfil Icon"
                                            sx={{ width: '3rem', height: '3rem' }}
                                        />
                                    </IconButton>
                                    {/* {loguedUser && ( */}
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={openMenu}
                                        onClose={handleMenuClose}
                                        sx={{ mt: '1.5rem' }}
                                    >
                                        {/* <MenuItem>
                                        <Typography>
                                           Usuario Javier Romero
                                        </Typography>
                                        </MenuItem> */}
                                        {loguedUser == false && (
                                            <MenuItem
                                                onClick={() => {
                                                    setOpenModalLogin(true)
                                                    handleMenuClose()
                                                }}
                                            >Iniciar Sesion</MenuItem>
                                        )}
                                        {loguedUser && rol == 'Administrador' && (
                                            <>
                                                <MenuItem onClick={() => {
                                                    handleMenuClose();
                                                    handleOpenModalProduct();
                                                }}>
                                                    <FeaturedPlayListIcon /> Gestión de Artículos
                                                </MenuItem>
                                                <MenuItem onClick={() => {
                                                    handleMenuClose();
                                                    setOpenModalCategories(true)
                                                }}>
                                                    <CategoryIcon /> Gestión de Categorias
                                                </MenuItem>

                                            </>
                                        )
                                        }
                                        {loguedUser == true && (
                                            <MenuItem onClick={() => {
                                                handleLogOff()
                                                handleMenuClose()
                                            }}>Cerrar Sesión</MenuItem>
                                        )}


                                    </Menu>
                                    {/* )} */}
                                </Grid>
                                <Grid
                                    sm={3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    item
                                    className='item iconHover'
                                >
                                    <Badge
                                        badgeContent={arrayProductShop.length}
                                        color="error"
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <svg
                                            onClick={() => {
                                                if (arrayProductShop.length > 0) {
                                                    setOpenModalPreOrder(true)
                                                }
                                            }
                                            }
                                            className="iconTransform"
                                            width="3rem"
                                            height="3rem"

                                        >
                                            <image href={carritoIcon} width="100%" height="100%" />
                                        </svg>
                                    </Badge>
                                </Grid>
                                <Grid
                                    sm={4}
                                    style={{
                                        marginRight: '1rem'
                                    }}
                                    item
                                    className='item'
                                >
                                    <button style={{ cursor: 'pointer' }} onClick={() => { setOpenModalRegister(true) }} class='botonRegistrarme'>
                                        REGISTRARME
                                    </button>
                                </Grid>

                            </Grid>
                        </Stack>
                    </Grid>
                    {loading && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bgcolor: 'rgba(0, 0, 0, 0.5)', // Esto oscurece el fondo mientras carga
                                zIndex: 1300
                            }}
                        >
                            <CircularProgress color="success" />
                        </Box>
                    )}
                </Grid>
                <RegisterModal
                    openModalRegister={openModalRegister}
                    setOpenModalRegister={setOpenModalRegister}
                />

                <LoginModal
                    openModalLogin={openModalLogin}
                    setOpenModalLogin={setOpenModalLogin}
                />
                <EditProductModal open={open} handleClose={handleCloseModalProduct } />
                <PreOrderModal open={openModalPreOrder} handleClose={() => { setOpenModalPreOrder(false) }} />
                <ModalCategories open={openModalCategories} handleClose={() => { setOpenModalCategories(false) }} />
            </ThemeProvider>
        </>
    );
}
