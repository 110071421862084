import React from "react";
import { Grid, Divider, Typography } from "@mui/material";
import useMainContext from "../context/useMainContext";
import MainHeader from "./containerHead/mainHeader";
import MainCarrousel from "./containerCarrousel/mainCarrousel";
import MobileCarrousel from "./containerCarrousel/mobileCarrousel";
import { ShopNavigator } from "./ShopNavigator/ShopNavigator";
import Footer from "./footer";
import { Section } from "./section";
import { SubCategories } from "./utils/SubCategories";
import '../Assets/styles.css';
import '../Assets/stylesFormRegister.css';
import { fetchArticlesBySubCategory } from "../controllers/articles";
import { SpecialCategoriesCatalog } from "./utils/SpecialCategoriesCatalog";



export const Index = () => {

  const { showMainCarrousel, setShowMainCarrousel, setArticles } = useMainContext();


  const handleCategorySelection = async (id_subCategory) => {
    if (id_subCategory) {
      setShowMainCarrousel(false)
      try {
        const response = await fetchArticlesBySubCategory(id_subCategory);
        setArticles(response);
      } catch (error) {
        console.log('Hubo un error al obtener los artículos:', error);
      }
    }

  };

  return (

    <Grid 
  container 
  spacing={1} 
  sx={{ 
    width: '100%', 
    margin: 0, 
    justifyContent: 'center', 
    alignItems: 'center', 
    overflowX: 'hidden' 
  }}
>
  <Grid item xs={12} sx={{ textAlign: 'center' }}>
    <MainHeader />
  </Grid>

  <Grid item xs={12} sx={{ marginTop: { xs: '3rem', sm: '4rem', md: '5rem', lg: '6rem' }, textAlign: 'center' }}>
    <Divider />
  </Grid>

  <Grid item xs={12} sx={{ marginBottom: { xs: '1rem', sm: '2rem', md: '3rem' }, textAlign: 'center' }}>
    {showMainCarrousel ? (
      <>
        <Grid sx={{ display: { xs: 'none', sm: 'block' }, justifyContent: 'center', alignItems: 'center', marginLeft: {xs:-1, sm:-1, md:-1, lg:-1}}}>
          <MainCarrousel />
        </Grid>
        <Grid sx={{ display: { xs: 'block', sm: 'none' }, justifyContent: 'center', alignItems: 'center', paddingRight: { xs: '1%', sm: '0' }, marginRight: { sm: '2rem' } }}>
          <MobileCarrousel />
        </Grid>
      </>
    ) : (
      <ShopNavigator />
    )}
  </Grid>

  <Grid 
    item 
    xs={12} 
    sx={{ 
      color: 'black', 
      py: 4, 
      mt: { xs: '1%', sm: '1%', md: '2%' }, 
      boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)', 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover', 
      backgroundPosition: 'bottom', 
      marginBottom: '-2rem', 
      paddingBottom: '3%', 
      textAlign: 'center', 
      width: '100%' 
    }}
  >
    <Grid sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '1%' }}>
      <Typography variant="h1" sx={{ color: '#228066', fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, fontWeight: 'bold', paddingLeft: '5%', marginBottom: {xs:'-10%', sm:'-10%', md:'0%', lg:'0%'} }}>
        Ofertas
      </Typography>
    </Grid>
    <SpecialCategoriesCatalog id_special_category={3} />
  </Grid>

  <Grid 
    item 
    xs={12} 
    sx={{ 
      color: 'black', 
      py: 4, 
      mt: { xs: '12%', sm: '3%', md: '4%' }, 
      boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)', 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover', 
      backgroundPosition: 'bottom', 
      paddingBottom: '3%', 
      textAlign: 'center', 
      width: '100%' 
    }}
  >
    <Grid sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '1%'  }}>
      <Typography variant="h1" sx={{ color: '#228066', fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, fontWeight: 'bold', paddingLeft: '3.5%'}}>
        Pack Super Precio:
      </Typography>
    </Grid>
    <SpecialCategoriesCatalog id_special_category={4} />
  </Grid>

  <Grid item xs={12} sx={{ textAlign: 'center', display: { sm: 'flex' } }}>
    <Section />
  </Grid>

  <Grid 
    item 
    xs={12} 
    sm={12} 
    sx={{ 
      color: 'black', 
      py: 4, 
      mt: { xs: '3%', sm: '4%', md: '6%' }, 
      boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)', 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover', 
      backgroundPosition: 'bottom', 
      paddingBottom: '3%', 
      textAlign: 'center' 
    }}
  >
    <Typography variant="h1" sx={{ color: '#228066', fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, fontWeight: 'bold', paddingRight: { xs: '10%', sm: '30%', md: '50%', lg: '70%' } }}>
      Encontra lo que buscas:
    </Typography>
    <SubCategories onCategoryClick={handleCategorySelection} />
  </Grid>

  <Grid item xs={12} sx={{ textAlign: 'center' }}>
    <Footer />
  </Grid>
</Grid>

  );
};